import React from "react"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {

  return (
    <>
      <Header siteTitle={'Browse badges and increase your Sales'} />
      <main>{children}</main>
      <footer
        style={{
          marginTop: `2rem`,
          textAlign: `center`
        }}
      >
        © {new Date().getFullYear()}, made by &nbsp;
        <a href="https://www.getmason.io">Mason</a>
      </footer>
    </>
  )
}


export default Layout
