import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Helmet } from "react-helmet"
import './header.scss'

const Header = ({ siteTitle }) => (
  <>
    <Helmet>
      <title>{siteTitle}</title>
    </Helmet>
    <header className='full-bleed layouted'>
      <div className="h_wrap">
        <Link to='/'>
          <StaticImage className={`logo_img`} objectFit='contain' src='../images/mmlogo.svg' alt='logo'/>
        </Link>
        <div className="actions">
          <a target='_blank' href='https://badge.getmodemagic.com/' className="s_cta m_hide">🚀 Boost your Sales!</a>
          <a href='https://getmodemagic.com/' className="p_cta">Start Free Trial</a>
        </div>
      </div>
    </header>
  </>
)

export default Header
